import { transportation } from '@/axios'

export default {
  addDriver (payload) {
    return transportation().post('drivers/company', payload)
  },
  getDriver (id) {
    return transportation().get(`drivers/${id}`)
  },
  editDriver (payload, id) {
    return transportation().patch(`drivers${id}`, payload)
  },
  getRequirementsDocuments () {
    return transportation().get('required-docs?filters[type]=driver')
  }
  // changeDefaultStore (payload) {
  //   return transportation().patch('merchant/change-default-store', payload)
  // }
}
